export const PREMIUMIZATION = 'premiumization';
export const RESEARCHROOM_FEATURE = 'researchroomEnabled';
export const CALL_REPLAY = 'callReplay';

export const featureToggle = {
  [PREMIUMIZATION]: {
    cookieName: PREMIUMIZATION,
  },
  [RESEARCHROOM_FEATURE]: {
    cookieName: RESEARCHROOM_FEATURE,
  },
  [CALL_REPLAY]: {
    cookieName: CALL_REPLAY,
  }
};
